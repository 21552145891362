import Styles from '@components/sign-in-drawer/components/help-link/styles';
import { snowplowButtonClick } from '@analytics/snowplow/events';

export default function HelpLink() {
  return (
    <Styles.Help
      href="https://help.pebmed.com.br/hc/pt-br/sections/360003141373-Sobre-login-e-status-da-conta?_gl=1*ectrs0*_gcl_au*NTIxOTY1MDgyLjE3MDAxNTE5ODU.*_ga*MTY0MzU2OTU3MS4xNzAwMTUxOTg1*_ga_N2JDKLB8ZZ*MTcwMzc5NzIzNS4zMi4xLjE3MDM3OTc4NDguNjAuMC4w"
      target="_blank"
      onClick={() => snowplowButtonClick('click_need_help', '/cadastro', null)}
    >
      Central de ajuda
    </Styles.Help>
  );
}
