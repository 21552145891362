import AuthLocalStorage from '@local-storage/auth';
import RemoteLoadSubscription, {
  RemoteLoadSubscription as RemoteLoadSubscriptionType,
} from '@remote-data/load-subscription';
import RemoteSignIn, { RemoteSignIn as RemoteSignInType } from '@remote-data/sign-in';
import { AuthenticationParams, UserModel } from '@remote-data/sign-in/types';
import { getBrowserNome, getBrowserVersion } from '@shared/utils';
import { handleError } from '@use-cases/errors/error-handling';

import { snowplowUser } from '@analytics/snowplow/events';
import { initializeBraze } from '@configs/braze';
import { sentryCaptureException } from '@configs/sentry';
import SubscriptionLocalStorage from '@local-storage/subscription';
import UserLocalStorage from '@local-storage/user';
import { HttpResponse } from '@remote-data/http-client/types';
import { UserSubscriptionModel } from '@remote-data/load-subscription/types';
import Auth from '@shared/auth';
import { normalizeTimeToRefreshToken } from '@shared/auth/utils';
import GlobalsCookie from '@shared/utils/cookie/globals';
import { SignInParams } from '@use-cases/sign-in/types';
import { joinSubscriptionInUser } from '@use-cases/sign-in/utils';

export class UseCaseSignIn {
  constructor(
    private readonly remoteSignIn: RemoteSignInType,
    private readonly remoteLoadSubscription: RemoteLoadSubscriptionType,
  ) {}

  async run(params: SignInParams): Promise<UserModel> {
    try {
      const userModel = await this.signInRemoteData(params);

      this.saveAuthLocalStorage(userModel.authToken, userModel.refreshToken);
      this.saveNameUserLocalStorage(userModel.firstName);

      const userSubscriptionResponse = await this.subscriptionRemoteData(userModel);
      const userAndSubscriptionModel = await this.mergeUserAndSubscriptionModel(userSubscriptionResponse, userModel);

      this.saveSubscriptionLocalStorage(userAndSubscriptionModel.isSubscription);
      this.saveTokenAuthCookie(userAndSubscriptionModel);

      userAndSubscriptionModel.isLoggedIn = true;

      snowplowUser(userAndSubscriptionModel);

      this.saveUserLocalStorage(userModel, userSubscriptionResponse.body);

      await initializeBraze(String(userAndSubscriptionModel.id));

      return userAndSubscriptionModel;
    } catch (error) {
      sentryCaptureException(error, {
        tags: {
          section: 'UseCaseSignIn',
        },
      });
      Auth.logout();

      return handleError(error, 'UseCaseSignIn', []);
    }
  }

  private async signInRemoteData(params: SignInParams) {
    const userResponse = await this.remoteSignIn.run(this.makeParamsRemoteSignIn(params));
    const { body: userModel } = userResponse;

    return userModel;
  }

  private async subscriptionRemoteData(userModel: UserModel) {
    return this.remoteLoadSubscription.run(userModel.authToken);
  }

  private async mergeUserAndSubscriptionModel(
    userSubscription: HttpResponse<UserSubscriptionModel>,
    userModel: UserModel,
  ) {
    const { body: userSubscriptionModel } = userSubscription;

    return joinSubscriptionInUser(userModel, userSubscriptionModel);
  }

  private makeParamsRemoteSignIn(params: SignInParams): AuthenticationParams {
    const emailOrCpf = params.email ? { email: params.email } : { cpf: params.cpf };

    return {
      ...emailOrCpf,
      senha: params.password,
      cod_app: `${process.env.NEXT_PUBLIC_APP_CODE_BACKEND}`,
      app_versao: `${process.env.NEXT_PUBLIC_APP_VERSION}`,
      so: 'WEB',
      so_versao: getBrowserVersion(),
      dispositivo: getBrowserNome(),
    };
  }

  private saveAuthLocalStorage(authToken, refreshToken) {
    AuthLocalStorage.setToken(authToken);
    AuthLocalStorage.setRefreshToken(refreshToken);
    normalizeTimeToRefreshToken(authToken);
  }

  private saveNameUserLocalStorage(name: string) {
    UserLocalStorage.setName(name);
  }

  private saveSubscriptionLocalStorage(subscription: boolean) {
    SubscriptionLocalStorage.setIsPremium(subscription);
  }

  private saveUserLocalStorage(user: UserModel, subscription) {
    UserLocalStorage.set(user, subscription);
  }

  private saveTokenAuthCookie(userModel: UserModel) {
    const cookieContent = GlobalsCookie.generateCookieContent(userModel);

    GlobalsCookie.set(cookieContent);
  }
}

export default new UseCaseSignIn(RemoteSignIn, RemoteLoadSubscription);
