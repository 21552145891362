export enum LoginMethodsEnum {
  EMAIL = 'email',
  CPF = 'cpf',
  LOGIN = 'login',
}

export enum LoginErrorsEnum {
  CPF_TOAST = 'O CPF inserido não é válido. Verifique e tente novamente.',
  EMAIL_TOAST = 'O e-mail inserido não é válido. Verifique e tente novamente.',
  CPF_EMAIL_TOAST = 'O CPF ou e-mail inserido não é válido. Verifique e tente novamente.',
  REQUIRED_FIELDS_TOAST = 'Preencha todos os campos obrigatórios para continuar.',
}
