import { KeysLocalStorageEnum } from '@local-storage/types';
import { UserModel } from '@remote-data/sign-in/types';
import SessionStorage from '@session-storage/index';
import { removeMaskCpf } from '@shared/helpers';
import UseCaseSignIn from '@use-cases/sign-in';
import router from 'next/router';
import { useState } from 'react';

interface HooksReturn {
  loading: boolean;
  userModel: UserModel;
  error: string;
  signIn(params: { email?: string; cpf?: string; password: string }): void;
  clearError(): void;
  errorCode: string;
}

export const useSignIn = (): HooksReturn => {
  const [loading, setLoading] = useState(false);
  const [userModel, setUserModel] = useState<UserModel>();
  const [error, setError] = useState('');
  const [errorCode, setErrorCode] = useState('');

  const signIn = async (params: { email?: string; cpf?: string; password: string }) => {
    try {
      setLoading(true);
      setError('');
      setErrorCode('');

      const data = await UseCaseSignIn.run(params);

      setUserModel(data);
    } catch (err) {
      setErrorCode(err?.code);

      if (err.code === 'AUTH_EMAIL_NOT_CONFIRMED') {
        saveDataToSessionStorage(params);
        router.push('/validar-email/');
        return;
      }
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const saveDataToSessionStorage = (params: { email?: string; cpf?: string; password: string }) => {
    const userLoginData = params.email ? { email: params.email } : { cpf: removeMaskCpf(params.cpf) };
    SessionStorage.set(KeysLocalStorageEnum.USER_LOGIN_DATA, userLoginData);
  };

  const clearError = () => {
    setError('');
  };

  return { loading, userModel, error, signIn, clearError, errorCode };
};
