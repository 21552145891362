import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background-color: ${({ theme }) => theme.tokens.red[200]};
  border-radius: 8px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.tokens.red[1200]};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.tokens.red[1200]};
  font-size: 14px;
  font-style: normal;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const Button = styled.button`
  margin-top: 16px;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 25px;
  border: none;
  background-color: ${({ theme }) => theme.tokens.gray[100]};
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }) => theme.tokens.blue[800]};
  flex-direction: row;

  div {
    display: flex;
  }
`;
