import { EmailFieldErrorsEnum } from '@components/input-email/messages';

export const emailValidationRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const rules = {
  required: EmailFieldErrorsEnum.REQUIRED_FIELD,
  pattern: {
    value: emailValidationRegExp,
    message: EmailFieldErrorsEnum.INVALID_EMAIL,
  },
  maxLength: {
    value: 100,
    message: EmailFieldErrorsEnum.INVALID_LENGTH,
  },
};

export function emailIsValidation(email: string) {
  const emailRegExp = new RegExp(emailValidationRegExp);
  return emailRegExp.test(email);
}
