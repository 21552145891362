import styled from 'styled-components';
import Link from 'next/link';

export default {
  ForgotPassword: styled(Link)`
    font-size: 14px;
    text-decoration: none;
    color: ${({ theme }) => theme.tokens.blue[800]};

    &:hover {
      text-decoration: underline;
    }
  `,
};
