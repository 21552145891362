import { useContext, useEffect, useRef } from 'react';
import InputCpf from '@components/input-cpf';
import { useFormContext } from 'react-hook-form';
import { CpfMessagesEnum } from '@components/input-cpf/messages';
import { LoginErrorsEnum } from '@screens/login/shared/enums';
import LoginPasswordInput from '@screens/login/components/password-input';
import { FormProps } from '@screens/login/shared/types';
import { snowplowLogin } from '@analytics/snowplow/events';
import { ContextConsumer } from '@context/index';

export default function ByCpf({ handleError, fieldsReadOnly }: FormProps) {
  const cpfRef = useRef('');
  const cpfMaxLength = 14;
  const { state } = useContext(ContextConsumer);
  const { register, errors, clearErrors, setValue } = useFormContext();

  const handleCpfInput = () => {
    if (cpfRef.current.length === cpfMaxLength) {
      snowplowLogin('web', 1, 'cpf', false, errors?.cpf?.message);
    }
  };

  useEffect(() => {
    clearErrors('password');
  }, []);

  useEffect(() => {
    if (errors?.cpf?.message === CpfMessagesEnum.INVALID_FIELD) {
      handleError(LoginErrorsEnum.CPF_TOAST);
    }
  }, [errors]);

  useEffect(() => {
    if (state?.signUpLogin?.loginValue) {
      setValue('cpf', state?.signUpLogin?.loginValue);
    }
  }, [state]);

  return (
    <>
      <InputCpf
        errors={errors}
        register={register}
        required={CpfMessagesEnum.REQUIRED_FIELD}
        value={cpfRef.current || ''}
        setValue={setValue}
        handleChange={(cpf) => (cpfRef.current = cpf)}
        onBlur={handleCpfInput}
        readOnly={fieldsReadOnly}
      />

      <LoginPasswordInput errors={errors} register={register} />
    </>
  );
}
