import { snowplowButtonClick } from '@analytics/snowplow/events';
import { ContextConsumer } from '@context/index';
import Styles from '@screens/login/components/forgot-password-link/styles';
import { useContext } from 'react';

export default function ForgotPasswordLink({ text = 'Esqueci minha senha', loginMethod }) {
  const { state } = useContext(ContextConsumer);

  const trackForgotPassword = () => {
    const snowplowProps = {
      cpf: {
        buttonName: 'click_forgot_password',
        additionalProps: 'CPF',
        location: 'signup_creation_screen_1',
      },
      email: {
        buttonName: 'click_forgot_password',
        additionalProps: 'email',
        location: 'signup_creation_screen_2',
      },
      login: {
        buttonName: 'click_forgot_password',
        additionalProps: 'login',
      },
    };

    if (state?.signUpLogin?.isSignUp) {
      snowplowButtonClick(
        snowplowProps[loginMethod].buttonName,
        'signup_web',
        snowplowProps[loginMethod].additionalProps,
        snowplowProps[loginMethod].location,
      );
    } else {
      snowplowButtonClick(
        snowplowProps[loginMethod].buttonName,
        'login_web',
        snowplowProps[loginMethod].additionalProps,
      );
    }
  };

  return (
    <Styles.ForgotPassword onClick={trackForgotPassword} href="/esqueci-senha">
      {text}
    </Styles.ForgotPassword>
  );
}
