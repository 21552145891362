import styled from 'styled-components';
import { device } from '@styles/media';

export default {
  ContainerButton: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 42px;
    @media ${device.mobile} {
      position: static;
    }
  `,

  Signup: styled.span`
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 32px;
    color: ${({ theme }) => theme.tokens.gray[1900]};

    @media ${device.mobile} {
      bottom: 20px;
    }
  `,

  Link: styled.a`
    color: ${({ theme }) => theme.tokens.blue[800]};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `,
};
