import { CpfMessagesEnum } from '@components/input-cpf/messages';

export const CPF_MAX_LENGTH = 14;

export const cpfValidationRegExp = /\d{3}\.\d{3}\.\d{3}\-\d{2}/;

export const rules = {
  required: CpfMessagesEnum.REQUIRED_FIELD,
  maxLength: CPF_MAX_LENGTH,
  pattern: {
    value: cpfValidationRegExp,
    message: CpfMessagesEnum.INVALID_FIELD,
  },
  validate: {
    cpf: (data) => check(data) || CpfMessagesEnum.INVALID_FIELD,
  },
};

export function check(data) {
  if (data === '') return true;

  // check if the data is null
  if (!data) return false;

  // check if the data is a valid CPF data
  return verify(data);
}

function verify(cpf) {
  const treatedCpf = cpf.replace(/\D/g, '');
  if (treatedCpf.toString().length !== 11 || /^(\d)\1{10}$/.test(treatedCpf)) return false;
  let result = true;
  [9, 10].forEach((j) => {
    let sum = 0;
    let r;
    treatedCpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach((e, i) => {
        sum += parseInt(e, 10) * (j + 2 - (i + 1));
      });
    r = sum % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r.toString() !== treatedCpf.substring(j, j + 1)) result = false;
  });
  return result;
}
