import styled from 'styled-components';
import Link from 'next/link';

export const Container = styled.div<{ isLogin: boolean }>`
  ${({ isLogin }) =>
    isLogin &&
    `
  input[name='email'] {
    margin-bottom: 16px;
  }
  label[for='password'] {
    margin-top: 32px;
  }`}
`;

export const ForgotEmail = styled(Link)`
  font-size: 14px;
  color: ${({ theme }) => theme.tokens.blue[800]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
