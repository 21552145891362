import Form from '@styles/form';
import { rules } from '@components/input-cpf-email/validation';
import { FormEvent, useState } from 'react';

export default function InputCpfEmail({ register, errors, value, name = 'login', handleChange, handleBlur }) {
  const [inputOnce, setInputOnce] = useState(false);

  const handleInput = (event: FormEvent<HTMLInputElement>) => {
    if (inputOnce) return;
    handleBlur(event);
    setInputOnce(true);
  };

  return (
    <Form.Field>
      <Form.Label htmlFor={name}>CPF ou e-mail</Form.Label>
      <Form.Input
        name={name}
        id={name}
        type="text"
        error={errors?.login}
        defaultValue={value}
        ref={register({ required: true, ...rules })}
        placeholder="Seu CPF ou e-mail"
        onChange={handleChange}
        onBlur={handleBlur}
        onInput={handleInput}
      />
      {errors?.login && <Form.Feedback>{errors.login.message}</Form.Feedback>}
    </Form.Field>
  );
}
