// Swagger
// https://api-acesso-homologacao.pebmed.com.br/docs/#/ACESSO/post_login

import { RemoteError } from '@remote-data/errors';
import AxiosHttpClient from '@remote-data/http-client';
import { HttpClient, HttpResponse, HttpStatusCode } from '@remote-data/http-client/types';
import { responseToUserModel } from '@remote-data/sign-in/adapters';
import { AuthenticationParams, UserModel } from '@remote-data/sign-in/types';

const makeApiUrl = (path: string): string => `${process.env.NEXT_PUBLIC_API_ACESSO}${path}`;

export class RemoteSignIn {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {}

  async run(params: AuthenticationParams): Promise<HttpResponse<UserModel>> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    if (httpResponse.statusCode === HttpStatusCode.Ok) {
      return responseToUserModel(httpResponse);
    }

    throw new RemoteError(httpResponse.statusCode, httpResponse.body?.code, httpResponse.body?.mensagem);
  }
}

export default new RemoteSignIn(makeApiUrl('/login'), AxiosHttpClient);
