import Recaptcha from '@components/recaptcha';
import { RecaptchaProps } from '@screens/login/components/recaptcha-wrapper/types';
import { useEffect, useRef } from 'react';
import * as Styles from '@screens/login/components/recaptcha-wrapper/styles';
import { RecaptchaErrorMessages } from '@components/recaptcha/error-messages';

export default function RecaptchaWrapper({
  error,
  setIsShowReCaptcha,
  maximumAccessAttempts = 3,
  showRecaptchaError,
}: RecaptchaProps) {
  const numberOfAccessAttemptsRef = useRef(0);

  const resetNumberOfAttempts = () => {
    numberOfAccessAttemptsRef.current = 0;
    setIsShowReCaptcha(false);
  };

  const canShowRecaptcha = () => {
    return numberOfAccessAttemptsRef.current > maximumAccessAttempts;
  };

  const updateNumberOfAccessAttempts = () => {
    if (canShowRecaptcha()) return false;

    numberOfAccessAttemptsRef.current += 1;

    return canShowRecaptcha();
  };

  useEffect(() => {
    if (error) {
      const showRecaptcha = updateNumberOfAccessAttempts();
      if (showRecaptcha) {
        setIsShowReCaptcha(showRecaptcha);
      }
    }
  }, [error]);

  return (
    canShowRecaptcha() && (
      <Styles.RecaptchaContainer error={showRecaptchaError}>
        <Recaptcha onChange={() => resetNumberOfAttempts()} />
        {showRecaptchaError && <Styles.RecaptchaError>{RecaptchaErrorMessages.FIELD}</Styles.RecaptchaError>}
      </Styles.RecaptchaContainer>
    )
  );
}
