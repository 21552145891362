import { Button } from '@pebmed/storybook-react';
import { snowplowButtonClick } from '@analytics/snowplow/events';
import { useRouter } from 'next/router';
import Styles from '@screens/login/components/buttons/styles';
import { useContext, useEffect, useState } from 'react';
import { ContextConsumer } from '@context/index';

export default function LoginButtons() {
  const { push } = useRouter();
  const { state } = useContext(ContextConsumer);
  const [loginMethod, setLoginMethod] = useState('');

  const trackSignUpEvent = (): void => {
    snowplowButtonClick('signup', '/login', null);
    push('/cadastro');
  };

  const trackLoginEvent = (): void => {
    snowplowButtonClick('click_login', 'login_web', loginMethod);
  };

  useEffect(() => {
    setLoginMethod(state.signUpLogin.loginMethod);
  }, [state.signUpLogin.loginMethod]);

  return (
    <Styles.ContainerButton>
      <Button
        type="submit"
        form="login-form"
        width="100%"
        height="50px"
        data-testid="login-button"
        onClick={trackLoginEvent}
      >
        Entrar
      </Button>

      <Styles.Signup>
        Não possui conta?{' '}
        <Styles.Link data-testid="signup-button" onClick={trackSignUpEvent}>
          Cadastre-se
        </Styles.Link>
      </Styles.Signup>
    </Styles.ContainerButton>
  );
}
