import { snowplowLogin } from '@analytics/snowplow/events';
import { ContextConsumer } from '@context/index';
import { clearRemoteIndexedDB } from '@hooks/use-remote-config/utils';
import { KeysLocalStorageEnum } from '@local-storage/types';
import { Loader } from '@pebmed/storybook-react';
import { useSignIn } from '@screens/hooks/use-sign-in';
import ByCpf from '@screens/login/components/by-cpf';
import ByCpfEmail from '@screens/login/components/by-cpf-email';
import ByEmail from '@screens/login/components/by-email';
import { ContainerProps } from '@screens/login/components/container/types';
import { LoginMethodsEnum } from '@screens/login/shared/enums';
import Styles from '@screens/login/styles';
import SessionStorage from '@session-storage/index';
import Auth from '@shared/auth';
import User from '@shared/utils/user';
import { SignInParams } from '@use-cases/sign-in/types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import RecaptchaWrapper from '@screens/login/components/recaptcha-wrapper';
import { RecaptchaErrorMessages } from '@components/recaptcha/error-messages';
import { useRemoteConfig } from '@hooks/use-remote-config';
import ForgotPasswordLink from '@screens/login/components/forgot-password-link';
import LoginButtons from '@screens/login/components/buttons';
import HelpLink from '@components/sign-in-drawer/components/help-link';
import { cookieDomain } from '@shared/utils';

export default function LoginContainer({
  loginMethod,
  fieldsReadOnly = true,
  handleSignInError,
  formMethods,
}: ContainerProps) {
  const [isShowReCaptcha, setIsShowReCaptcha] = useState(false);
  const showRecaptchaErrorRef = useRef(false);

  const { state } = useContext(ContextConsumer);

  const { loading, userModel, error, signIn, clearError, errorCode } = useSignIn();
  const { handleSubmit } = formMethods;
  const isUserPremium = User.isUserPremium();
  const { getCachedValues } = useRemoteConfig();

  const isRecaptchaEnabledRef = useRef(true);

  useEffect(() => {
    if (state?.remoteConfig?.data) {
      const value = getCachedValues('web_recaptcha');
      isRecaptchaEnabledRef.current = value?.web_recaptcha?.data.signIn;
    }
  }, [state?.remoteConfig?.data]);

  const handleError = (message: string) => {
    if (handleSignInError) handleSignInError(message, errorCode);
    clearError();
  };

  const renderRecaptcha = useCallback(
    () =>
      isRecaptchaEnabledRef.current && (
        <RecaptchaWrapper
          error={error}
          setIsShowReCaptcha={setIsShowReCaptcha}
          showRecaptchaError={showRecaptchaErrorRef.current}
        />
      ),
    [getCachedValues('web_recaptcha')],
  );

  const submitForm = handleSubmit(async (data: SignInParams) => {
    const loginData = {};
    if (data.login) {
      loginData[state.signUpLogin.loginMethod] = data.login;
    }
    if (!isShowReCaptcha) {
      clearRemoteIndexedDB();
      showRecaptchaErrorRef.current = false;
      Auth.logout();
      await signIn({ ...data, ...loginData });
    } else {
      showRecaptchaErrorRef.current = true;
      handleError(RecaptchaErrorMessages.TOAST);
    }
  });

  const components = {
    email: <ByEmail handleError={handleError} fieldsReadOnly={fieldsReadOnly} />,
    cpf: <ByCpf handleError={handleError} fieldsReadOnly={fieldsReadOnly} />,
    login: <ByCpfEmail handleError={handleError} />,
  };

  const handleNavigationSignIn = async () => {
    let destinationRoute = '/home';
    const urlToRedirect = SessionStorage.get(KeysLocalStorageEnum.LOGIN_REDIRECT);

    if (urlToRedirect !== null) {
      destinationRoute = urlToRedirect;
    }

    redirectAfterLogin(destinationRoute);
  };

  const redirectAfterLogin = (url: string): void => {
    let urlToRedirect = url;
    SessionStorage.set(KeysLocalStorageEnum.LOGIN_REDIRECT, null);
    const readCookie = document.cookie;

    if (readCookie && readCookie.includes('origin_login')) {
      if (readCookie.includes('AW-boleto')) {
        urlToRedirect = process.env.NEXT_PUBLIC_PAYMENT_METHOD_CHECKOUT_BANKSLIP;
      } else if (readCookie.includes('AW') && isUserPremium) {
        urlToRedirect = `${process.env.NEXT_PUBLIC_APP_URL}/assinatura`;
      } else {
        urlToRedirect = process.env.NEXT_PUBLIC_PAYMENT_METHOD_CHECKOUT;
      }
      document.cookie = `origin_login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/login;domain=${cookieDomain()}`;
    }

    window.location.href = urlToRedirect;
  };

  const stepValue = () => {
    switch (loginMethod) {
      case LoginMethodsEnum.CPF:
        return 'CPF';
      case LoginMethodsEnum.EMAIL:
        return 'email';
      case LoginMethodsEnum.LOGIN:
        return state.signUpLogin.loginMethod === LoginMethodsEnum.CPF ? 'CPF' : 'email';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (userModel) {
      handleNavigationSignIn();
      snowplowLogin('web', 4, 'login_success', true, null, stepValue());
    }
  }, [userModel]);

  useEffect(() => {
    if (error) {
      let errorMessageByLoginMethod = '';
      switch (loginMethod) {
        case LoginMethodsEnum.CPF:
          errorMessageByLoginMethod = error.replace('Login', 'CPF');
          break;
        case LoginMethodsEnum.EMAIL:
          errorMessageByLoginMethod = error.replace('Login', 'E-mail');
          break;
        case LoginMethodsEnum.LOGIN:
          errorMessageByLoginMethod = error;
          break;
        default:
          break;
      }
      handleError(errorMessageByLoginMethod);
      snowplowLogin('web', 4, 'login_error', true, errorMessageByLoginMethod, stepValue());
    }
  }, [error]);

  useEffect(() => {
    setIsShowReCaptcha(false);
  }, [loginMethod]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...formMethods}>
      {loading && <Loader />}
      <Styles.FormContainer id="login-form" onSubmit={submitForm}>
        {components[loginMethod]}

        <Styles.Links>
          <ForgotPasswordLink
            text={loginMethod === LoginMethodsEnum.CPF ? 'Esqueci meu e-mail ou senha' : undefined}
            loginMethod={loginMethod}
          />
          <HelpLink />
        </Styles.Links>

        {renderRecaptcha()}
        {loginMethod === LoginMethodsEnum.LOGIN && <LoginButtons />}
      </Styles.FormContainer>
    </FormProvider>
  );
}
