import { HttpResponse } from '@remote-data/http-client/types';
import { UserModel } from '@remote-data/sign-in/types';

const responseToUserAddressModel = (userAddress) => ({
  cep: userAddress?.cep,
  uf: userAddress?.uf,
});

export const responseToUserModel = ({ statusCode, body }: HttpResponse): HttpResponse<UserModel> => ({
  statusCode,
  body: {
    isLoggedIn: false,
    authToken: body.accessToken,
    refreshToken: body.refreshToken,
    id: body.usuario.id,
    email: body.usuario.email,
    firstName: body.usuario.nome,
    secondName: body.usuario.sobrenome,
    cpf: body.usuario.cpf,
    userTypeId: body.usuario.id_tipo_usuario,
    medicalFormationId: body.usuario.id_formacao_medica,
    specialtyId: body.usuario.id_especialidade,
    degreeFormationType: body.usuario.grau_formacao,
    areaExpertiseId: body.usuario.id_area_atuacao,
    professionalCouncilNumber: body.usuario.num_conselho_profis,
    address: responseToUserAddressModel(body.usuario.endereco),
    profileId: body.usuario.perfil_usuario,
    graduation: body.usuario.graduacao,
    requestUpdate: body.usuario.solicitar_atualizacao,
    active: body.usuario.ativo,
  },
});
