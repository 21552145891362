import { useEffect, useRef } from 'react';
import { PropsType } from '@components/input-email/types';
import { rules } from '@components/input-email/validation';
import Tooltip from '@components/tooltip';
import Form from '@styles/form';

export default function InputEmail({
  field,
  errors,
  register,
  onBlur,
  tooltip = false,
  labelField = 'E-mail',
  placeholder = 'email@email.com.br',
  applyRules = false,
  disabled = false,
  handleChange,
  value,
  readOnly,
}: PropsType) {
  const errorField = errors[field];
  const registerRules = applyRules ? rules : { required: 'Campo obrigatório' };

  const fieldRef = useRef(null);

  useEffect(() => {
    const emailRef = fieldRef.current.children[1];
    emailRef.value = value;
  }, []);

  const handleValue = () => {
    const emailRef = fieldRef.current.children[1];

    handleChange(emailRef.value);
  };

  return (
    <Form.Field ref={fieldRef}>
      <Form.Label htmlFor={field} readOnly={readOnly} error={errorField}>
        {labelField}
      </Form.Label>
      <Form.Input
        name={field}
        id={field}
        error={errorField}
        ref={register(registerRules)}
        type="email"
        placeholder={placeholder}
        onChange={handleValue}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={readOnly}
        data-testid="inputEmail"
      />
      <Form.Feedback>{errorField ? errorField.message : ''}</Form.Feedback>
      {tooltip && (
        <Tooltip
          message="O email é a sua credencial para acessar o Whitebook, por isso é preciso que você informe um email para ter acesso ao serviço."
          align="right"
        />
      )}
    </Form.Field>
  );
}
