import styled, { css } from 'styled-components';

export const Container = styled.div<{ cpfEmailError: boolean; passwordError: boolean }>`
  & label {
    color: ${({ theme }) => theme.tokens.gray[1900]};
  }
  & input {
    font-size: 1rem;
  }
  ${({ cpfEmailError }) => {
    return (
      cpfEmailError &&
      css`
        & label[for='login'] {
          color: ${({ theme }) => theme.tokens.red[550]};
        }
        & input[id='login'] {
          border-bottom: 2px solid ${({ theme }) => theme.tokens.red[550]};
        }
      `
    );
  }}
  ${({ passwordError }) => {
    return (
      passwordError &&
      css`
        & label[for='password'] {
          color: ${({ theme }) => theme.tokens.red[550]};
        }
        & input[id='password'] {
          border-bottom: 2px solid ${({ theme }) => theme.tokens.red[550]};
        }
      `
    );
  }}
`;
