import InputEmail from '@components/input-email';
import { useContext, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import LoginPasswordInput from '@screens/login/components/password-input';
import { FormProps } from '@screens/login/shared/types';
import { snowplowButtonClick, snowplowLogin } from '@analytics/snowplow/events';
import { LoginErrorsEnum } from '@screens/login/shared/enums';
import { EmailFieldErrorsEnum } from '@components/input-email/messages';
import { ContextConsumer } from '@context/index';
import * as Styles from '@screens/login/components/by-email/styles';

export default function ByEmail({ handleError, fieldsReadOnly }: FormProps) {
  const emailRef = useRef('');
  const { state } = useContext(ContextConsumer);
  const { register, errors, trigger, clearErrors, setValue } = useFormContext();

  const handleOnChange = (email) => {
    emailRef.current = email;
    clearErrors('email');
  };

  const trackEmailField = async () => {
    await trigger('email');
    let errorMessage = null;
    if (errors?.email) {
      errorMessage = 'Email inválido';
    }
    snowplowLogin('web', 1, 'email', false, errorMessage);
  };

  useEffect(() => {
    clearErrors('password');
  }, []);

  useEffect(() => {
    if (
      errors?.email?.message === EmailFieldErrorsEnum.INVALID_EMAIL ||
      errors?.email?.message === EmailFieldErrorsEnum.INVALID_LENGTH
    ) {
      handleError(LoginErrorsEnum.EMAIL_TOAST);
    }
  }, [errors]);

  useEffect(() => {
    if (state?.signUpLogin?.loginValue) {
      setValue('email', state?.signUpLogin?.loginValue);
    }
  }, [state]);

  const buttonTracking = () => {
    snowplowButtonClick('click_forgot_email', 'login_web');
  };

  return (
    <Styles.Container isLogin={!state?.signUpLogin?.loginValue}>
      <InputEmail
        field="email"
        errors={errors}
        register={register}
        value={emailRef.current || ''}
        handleChange={(email) => handleOnChange(email)}
        applyRules
        onBlur={trackEmailField}
        readOnly={fieldsReadOnly}
      />

      {!state?.signUpLogin?.loginValue && (
        <Styles.ForgotEmail href="/esqueci-email" onClick={buttonTracking}>
          Esqueci meu e-mail
        </Styles.ForgotEmail>
      )}

      <LoginPasswordInput errors={errors} register={register} />
    </Styles.Container>
  );
}
