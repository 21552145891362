import { snowplowLogin } from '@analytics/snowplow/events';
import InputPassword from '@components/input-password';
import { LoginPasswordInputProps } from '@screens/login/components/password-input/types';
import { useRef, useContext } from 'react';
import Styles from '@screens/login/components/password-input/styles';
import { ContextConsumer } from '@context/index';

export default function LoginPasswordInput({ errors, register }: LoginPasswordInputProps) {
  const passwordRef = useRef('');
  const { state } = useContext(ContextConsumer);

  const handleChange = (password: string) => {
    // eslint-disable-next-line no-param-reassign
    passwordRef.current = password;
  };

  const trackPasswordField = () => {
    snowplowLogin('web', 2, 'password', false);
  };

  const handleHideError = () => {
    return state?.signUpLogin?.isSignUp && errors?.password?.message !== 'Campo obrigatório';
  };

  return (
    <Styles.Container>
      <InputPassword
        field="password"
        labelField="Senha"
        errors={errors}
        register={register}
        placeholder="Sua senha"
        value={passwordRef.current || ''}
        handleChange={(value) => handleChange(value)}
        onBlur={trackPasswordField}
        hideError={handleHideError()}
      />
    </Styles.Container>
  );
}
