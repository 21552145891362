import InputCpfEmail from '@components/input-cpf-email';
import GeneralStyles from '@screens/login/styles';
import { FormProps } from '@screens/login/shared/types';
import { useFormContext } from 'react-hook-form';
import { FormEvent, useEffect, useRef, useContext } from 'react';
import { CPFMask } from '@shared/helpers';
import { snowplowButtonClick, snowplowLogin } from '@analytics/snowplow/events';
import LoginPasswordInput from '@screens/login/components/password-input';
import { LoginErrorsEnum, LoginMethodsEnum } from '@screens/login/shared/enums';
import { emailValidationRegExp } from '@components/input-email/validation';
import { cpfValidationRegExp } from '@components/input-cpf/validation';
import { ContextConsumer } from '@context/index';
import { SignUpLoginActionsEnum } from '@context/sign-up-login/types';
import * as Styles from '@screens/login/components/by-cpf-email/styles';

export default function ByCpfEmail({ handleError }: FormProps) {
  const CPF_MAX_LENGTH = 14;
  const FIELD_NAME = 'login';

  const { register, errors, setValue, clearErrors, trigger } = useFormContext();
  const { dispatch } = useContext(ContextConsumer);
  const valueRef = useRef('');

  useEffect(() => {
    if (errors?.login?.message === 'Campo inválido') {
      handleError(LoginErrorsEnum.CPF_EMAIL_TOAST);
    }
    if (errors?.login?.message === 'Campo obrigatório' || errors?.password?.message === 'Campo obrigatório') {
      handleError(LoginErrorsEnum.REQUIRED_FIELDS_TOAST);
    }
  }, [errors]);

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    valueRef.current = event.currentTarget.value;
    if (valueRef.current.length > 100) {
      trigger('login');
    } else {
      clearErrors('login');
    }
  };

  const handleBlur = async (event: FormEvent<HTMLInputElement>) => {
    valueRef.current = event.currentTarget.value;
    const regex = /[a-zA-Z!#$%&'*+\/=?^_`{|}~@]+/;
    const hasTextAndSymbols = regex.test(event.currentTarget.value);
    if (event.currentTarget.value.length < CPF_MAX_LENGTH && !hasTextAndSymbols) {
      valueRef.current = CPFMask(event.currentTarget.value);
    }
    setValue(FIELD_NAME, valueRef.current);
    await trigger('login');
    dispatchLoginMethod(valueRef.current);
    snowplowLogin('web', 1, hasTextAndSymbols ? 'email' : 'cpf', false, errors?.login?.message);
  };

  const buttonTracking = () => {
    snowplowButtonClick('click_forgot_email', 'login_web');
  };

  const dispatchLoginMethod = (value: string) => {
    if (emailValidationRegExp.test(value)) {
      dispatch({ type: SignUpLoginActionsEnum.SET_LOGIN_METHOD, payload: LoginMethodsEnum.EMAIL });
    }
    if (cpfValidationRegExp.test(value)) {
      dispatch({ type: SignUpLoginActionsEnum.SET_LOGIN_METHOD, payload: LoginMethodsEnum.CPF });
    }
  };

  return (
    <Styles.Container cpfEmailError={errors?.login} passwordError={errors?.password}>
      <InputCpfEmail
        register={register}
        errors={errors}
        value={valueRef.current || ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />

      <GeneralStyles.ForgotEmail href="/esqueci-email" onClick={buttonTracking}>
        Esqueci meu e-mail
      </GeneralStyles.ForgotEmail>

      <LoginPasswordInput errors={errors} register={register} />
    </Styles.Container>
  );
}
