import * as Styles from '@components/callout-card/styles';
import { useRouter } from 'next/router';
import { CalloutCardTypes } from '@components/callout-card/types';

export default function CalloutCard({ title, description, buttons }: CalloutCardTypes) {
  const { push } = useRouter();

  const renderButtons = () => {
    const MAX_SIZE = 2;

    return buttons.map(
      (button, key: number) =>
        key < MAX_SIZE && (
          <Styles.Button key={button.name} onClick={() => push(button.url)}>
            {button.icon}
            {button.name}
          </Styles.Button>
        ),
    );
  };

  return (
    <Styles.Container data-testid="callout-card">
      <Styles.Title>{title}</Styles.Title>
      <Styles.Description dangerouslySetInnerHTML={{ __html: description }} />
      <Styles.ButtonContainer>{renderButtons()}</Styles.ButtonContainer>
    </Styles.Container>
  );
}
