import { KeysLocalStorageEnum } from '@local-storage/types';

export class SessionStorage {
  set(key: KeysLocalStorageEnum, value): void {
    if (value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.removeItem(key);
    }
  }

  get(key: KeysLocalStorageEnum) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  clear(): void {
    sessionStorage.clear();
  }
}
export default new SessionStorage();
