import styled from 'styled-components';

import { device } from '@styles/media';
import Form from '@styles/form';
import Link from 'next/link';

export default {
  Content: styled.main`
    min-height: 100vh;

    input[name='cpf'],
    input[name='email'] {
      color: ${({ theme }) => theme.tokens.gray[1900]};
    }

    label[for='cpf'],
    label[for='email'] {
      color: ${({ theme }) => theme.tokens.gray[1400]};
    }

    @media ${device.mobile} {
      min-height: auto;
    }
  `,
  LoginWrapper: styled.section`
    max-width: 330px;
    display: flex;
    flex-direction: column;
    margin: 0 auto 48px;
    padding-top: 96px;
    input {
      border-bottom: 1px solid ${({ theme }) => theme.tokens.gray[1200]};
    }
    @media ${device.mobile} {
      padding-top: 32px;
    }
  `,
  Title: styled.h2`
    font-weight: normal;
    letter-spacing: -0.02em;
    text-align: left;
    font-size: 20px;
    margin-bottom: 9px;
    color: ${({ theme }) => theme.tokens.gray[1900]};
    @media ${device.mobile} {
      margin-top: 0;
    }
  `,
  SubTitle: styled.h3`
    font-weight: normal;
    letter-spacing: -0.02em;
    text-align: left;
    font-size: 14px;
    margin-top: 0;
    color: ${({ theme }) => theme.tokens.gray[1900]};
    margin-bottom: 32px;
  `,
  FormContainer: styled(Form.FormContainer)`
    padding: 0;
    height: auto;
    margin: 8px 0 0;

    ${Form.Field} {
      margin-bottom: 16px;
    }
  `,
  Links: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 48px;
  `,
  Faq: styled.span`
    color: ${({ theme }) => theme.tokens.blue[800]};
  `,
  ForgotEmail: styled(Link)`
    font-size: 14px;
    color: ${({ theme }) => theme.tokens.blue[800]};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `,
  CalloutCardContainer: styled.div`
    margin-bottom: 24px;
  `,
};
