import styled from 'styled-components';
import Link from 'next/link';

export default {
  Help: styled(Link)`
    font-size: 12px;
    text-decoration: none;
    color: ${({ theme }) => theme.tokens.blue[800]};
    gap: 4px;

    &:hover {
      text-decoration: underline;
    }
  `,
};
