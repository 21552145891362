export const rules = {
  required: 'Campo obrigatório',
  minLength: {
    value: 8,
    message: 'A senha deve conter entre 8 e 16 caracteres',
  },
  maxLength: {
    value: 16,
    message: 'A senha deve conter entre 8 e 16 caracteres',
  },
};

function calculatePasswordStrengthLevel(password: string) {
  if (password.length < 8) {
    return 0;
  }

  const hasUpper = /[A-Z]/;
  const hasLower = /[a-z]/;
  const hasNumber = /[0-9]/;
  // eslint-disable-next-line
  const hasSpecialCharacters = /[\~\/\[\]\|\-\,\:\?\'\`\<\>\{\}\;\!\@\#\$\%\^\&\*\)\(+\=\._-]/;
  let score = 0;
  if (hasUpper.test(password)) {
    score += 1;
  }
  if (hasLower.test(password)) {
    score += 1;
  }
  if (hasNumber.test(password)) {
    score += 1;
  }
  if (hasSpecialCharacters.test(password)) {
    score += 1;
  }
  return score;
}

export function getPasswordStrengthType(password: string) {
  switch (calculatePasswordStrengthLevel(password)) {
    case 1:
      return 'weak';
    case 2:
    case 3:
      return 'moderate';
    case 4:
      return 'strong';
    default:
      return '';
  }
}
