import styled, { css } from 'styled-components';

export const RecaptchaContainer = styled.div<{ error: boolean }>`
  width: 320px;
  margin-bottom: 48px;
  padding: 0;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.tokens.red[540]};
      padding: 8px;
    `}

  & > div > div > div {
    margin: 0;
    width: 0;
  }
`;

export const RecaptchaError = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.tokens.red[600]};
  margin: 8px 0 0 0;
`;
