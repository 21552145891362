import { CpfEmailMessagesEnum } from '@components/input-cpf-email/messages';
import { check, cpfValidationRegExp } from '@components/input-cpf/validation';
import { EmailFieldErrorsEnum } from '@components/input-email/messages';
import { emailValidationRegExp } from '@components/input-email/validation';

export const rules = {
  required: CpfEmailMessagesEnum.REQUIRED_FIELD,
  validate: {
    cpfOrEmail: (data) => validateCpfEmailField(data) || CpfEmailMessagesEnum.INVALID_FIELD,
  },
  maxLength: {
    value: 100,
    message: EmailFieldErrorsEnum.INVALID_LENGTH,
  },
};

export const validateCpfEmailField = (value: string) => {
  if (emailValidationRegExp.test(value)) {
    return true;
  }
  if (cpfValidationRegExp.test(value)) {
    return check(value);
  }
  return false;
};
